html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(193,193,193);
  outline: 1px solid slategrey;
  border-radius: 2px;
}
